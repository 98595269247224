/* @import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"); */

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
:root {
  --max-width: 1100px;
  --border-radius-6: 6px;
  --border-radius-10: 10px;
  --border-radius: 12px;

  /* main colors */
  /* --primary-color: #0d53b3; #1C4C9A */
  /* --primary-color: #1c4e80; */
  /* --primary-color: #5c43ff; */
  /* --primary-color: #efefef; */
  --primary-color: #7849fd;
  --secondary-color: #f51a44;
  --black-color: #000000;
  --blue-dark: #0d53b3;
  --blue-light: #e7f1fd;
  --blue-light-100: #edf4fb;
  --blue-light-200: #ebf3ff;
  --light-900: #f3f9ff;
  --pink-light: #fff1f2;
  --yellow-light: #fdf6e4;
  --success-color: #64b75e;
  --success-color-hover: #8bbf86;

  /* border colors */
  --border-gray-1: #dddddd;

  /* text colors */
  --text-primary: #424242;
  --text-white: #ffffff;
  --text-dark-blue: #0c53b2;
  --text-black: #1e1e1e;
  --text-gray-1: #696871;
  --text-gray-2: #646464;
  --text-gray-3: #5b5b64;
  --text-gray-4: #939393;
  --text-gray-5: #bab1b1;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  /* font-family: "Roboto", sans-serif; */
  font-family: "Poppins", sans-serif;
}

html,
body {
  font-family: "Roboto", sans-serif;
  background: #ebe9eb;
}
/* width */
body::-webkit-scrollbar {
  width: 10px;
}

/* Track */
body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
body::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
body::-webkit-scrollbar-thumb:hover {
  background: #555;
}

a {
  color: inherit;
  text-decoration: none;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}
table thead tr th {
  text-wrap: nowrap;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
p {
  margin-bottom: 0;
}

button,
input[type="button"],
input[type="reset"],
input[type="file"],
input[type="submit"] {
  cursor: pointer;
}
.row {
  margin-left: 0;
  margin-right: 0;
}

/* bg related */
.bg-primary {
  background-color: var(--primary-color) !important;
}
/* Text related */
.text-primary-color {
  color: var(--text-primary);
}
/* Font related */
.font-12 {
  font-size: 12px;
}
.font-14 {
  font-size: 14px;
}
.font-16 {
  font-size: 16px;
}
.font-18 {
  font-size: 18px;
}
.font-24 {
  font-size: 24px;
}
.font-weight-600 {
  font-weight: 600;
}
.font-weight-400 {
  font-weight: 400;
}

/* Colors */
.success-bg-color {
  background-color: var(--success-color);
}
.success-bg-color:hover {
  background-color: var(--success-color-hover);
}
.success-bg-color:focus {
  background-color: var(--success-color-hover) !important;
}

.text-gray-color {
  /* color: var(--text-gray-5); */
  color: #f51a44;
}

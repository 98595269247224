/* splash-screen */
.splash-screen {
  position: fixed;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #5555553e;
  top: 0;
  left: 0;
}
.cursor-pointer {
  cursor: pointer;
}
/* Error Message */
.error-message-section ul li {
  list-style: disc;
  margin-left: 18px;
}
/* Dashboard section start */
.dashboard_layout_section {
  /* height: 100vh; */
  /* z-index: -999; */
  min-height: 100vh;
}
.dashboard_layout_child_section {
  /* padding: 20px 50px; */

  /* overflow-y: auto; */
  /* max-height: 100vh; */
}
@media screen and (max-width: 525px) {
  .dashboard_layout_child_section {
    /* padding: 20px 20px; */
  }
}
.dashboard_layout_main {
  overflow-y: auto;
}
/* Dashboard section end */

/* Aside Section start */
.aside_section {
  background-color: #efefef;

  height: calc(100vh - 0px);
  position: sticky;
  overflow-y: hidden;
  top: 0px;
  /* overflow-y: auto; */
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
  display: flex;
  flex-direction: column;
}
.aside_section_showMenu_width {
  width: 260px;
}
.aside_section_showHide_width {
  width: 60px;
}
.aside_section_section_show {
  display: block;
}
.aside_section_section_hide {
  display: none !important;
}
.aside_section_menu_option {
  padding: 4px;
  position: fixed;
  /* background-color: #ffffff; */
  background-color: var(--primary-color);
  color: rgb(237, 216, 216);
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
  /* aspect-ratio: 1/1; */
}
.aside_section_menu_option_showOption {
  top: 40px;
  left: 250px;
  /* border-bottom-right-radius: 26px;
  border-top-right-radius: 26px; */
}
.aside_section_menu_option_hideOption {
  transform: rotate(180deg);
  /* border-bottom-left-radius: 26px;
  border-top-left-radius: 26px; */
  top: 20px;
  left: 50px;
}
/* width */
.aside_section > div::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.aside_section > div::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.aside_section > div::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.aside_section > div::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.aside_section > div {
  overflow-y: auto;
}
.aside_logo_section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.aside_logo_section img {
  margin-top: 20px;
  /* width: 120px; */
  height: 50px;
}
.aside_logo_section h3 {
  /* text-align: center; */
  margin-top: 8px;
  text-transform: uppercase;
}
.aside_menu_section {
  margin-top: 40px;
}
.aside_menu_item {
  padding-left: 12px;
  padding-right: 24px;
  margin: 0 12px;
}
.aside_menu_item_active {
  /* background-color: #75869e; */
  /* background-color: #8e7ef7; */
  background-color: #e3e0e0;
  /* opacity: 0.6; */
  border-radius: 4px;
}
.aside_menu_item .aside_menu_item_link img {
  width: 24px;
  height: 24px;
}
.aside_menu_item .aside_menu_item_link .menu-icon {
  width: 24px;
  height: 24px;
}
.aside_menu_item .aside_menu_item_link .svg-icon {
  width: 24px;
  height: 24px;
  color: var(--text-primary);
}
.aside_menu_item .aside_menu_item_link p {
  margin-left: 14px;
  font-size: 14px;
  color: var(--text-primary);
  line-height: 18px;
  margin-bottom: 0px;
}
.aside_menu_logout {
  /* margin-top: 42px; */
  cursor: pointer;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;

  color: #ededed;
  font-weight: 700;
  font-size: 16px;
}
/* Aside Section end */
/* top header section start*/
.top_header_text h1 {
  color: var(--primary-color);
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 600;
}
.top_header_text span {
  font-size: 14px;
}
.top_header_search button,
.top_header_search input {
  all: unset;
}
.top_header_search button {
  position: absolute;
  left: 10px;
  top: 8px;
}
.top_header_search input {
  background: #ededed;
  border-radius: 6px;
  text-indent: 34px;
  height: 40px;
  width: 200px;
}
.top_header_dropDown .dropdown-toggle {
  all: unset;
}
.top_header_dropDown .dropdown-menu {
  transform: translate(-15px, 49px) !important;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  /* border: none; */
}

.top_header_dropDown .dropdown-toggle::after {
  display: none;
}
.top_header_user_info img {
  border-radius: 50%;
  object-fit: cover;
  height: 40px;
  width: 40px;
}
.top_header_user_info p {
  font-size: 14px;
}
.logout-header-modal .modal-sm {
  max-width: 400px;
}

/* top header section end*/
/* dashboard cards section start*/
.dashboard_card {
  background-image: url("../images/common/card_bg.png");
  background-size: cover;
  /* background-repeat: no-repeat; */
  padding: 16px;
  text-align: center;
}
.dashboard_card_text h1 {
  color: #ffffff;
  font-size: 14px;
  margin-bottom: 6px;
}
.dashboard_card_text p {
  color: #ffffff;
  font-weight: 600;
  font-size: 17px;
}
.dashboard_card_icon {
  border-radius: 50px;
  height: 60px;
  width: 60px;
}

.dashboard_calender .fc-toolbar-title {
  color: var(--text-primary);
  font-size: 16px;
  font-weight: 600;
}
.dashboard_calender .fc-toolbar-chunk .fc-today-button {
  background-color: var(--primary-color);
  text-transform: capitalize;
}
.dashboard_calender .fc-toolbar-chunk .fc-button:disabled {
  opacity: 1;
}
.dashboard_calender .fc-toolbar-chunk button {
  background-color: var(--primary-color);
}
.dashboard_calender .fc-theme-standard th {
  background-color: var(--primary-color) !important;
  color: #ffffff;
  padding-top: 6px;
  padding-bottom: 0px;
}
.dashboard_calender .fc .fc-daygrid-day.fc-day-today {
  background-color: rgba(120, 73, 253, 0.6);
}
.dashboard_calender .fc-daygrid-event-harness {
  /* background-color: var(--primary-color); */
  color: white;
  margin-bottom: 4px;
  border-radius: 4px;
}
.dashboard_calender .fc-daygrid-day-events {
  padding-left: 8px;
  padding-right: 8px;
}
.dashboard_calender .fc-daygrid-event-dot {
  border-color: rgb(236, 77, 77);
}
.dashboard_calender .fc-h-event .fc-event-title-container {
  background-color: var(--primary-color);
  color: white;
}
.dashboard_calender .fc-h-event {
  border: none;
}
.calendar-holiday-message {
  padding: 8px;
  background-color: rgb(236, 77, 77);
  border-radius: 4px;
}
.calendar-user-detail {
  padding: 4px;
  border-radius: 4px;
  background-color: var(--primary-color);
}
/* dashboard cards section end*/
/* common table section Start*/
.table_section thead tr th {
  background-color: var(--primary-color);
  font-weight: 400;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 16px;
  color: #ffffff;
}
.table_section tbody tr td {
  padding-bottom: 10px;
  padding-top: 10px;
  border: none;
  font-size: 15px;
  color: #555;
}
.table_section tbody tr:nth-child(even) td {
  background-color: #f1f1f1;
}

/* common table section End*/

/* Filter section search Start*/

.filter_section_search button,
.filter_section_search input {
  all: unset;
}
.filter_section_search button {
  position: absolute;
  right: 10px;
  top: 8px;
}
.filter_section_search input {
  background: #ededed;
  border-radius: 6px;
  text-indent: 14px;
  height: 40px;
}
.filter_section_filter_button {
  all: unset;
  background: var(--primary-color);
  font-size: 14px;
  height: 40px;
  width: 140px;
}

.filter_section_show_per_page p {
  font-size: 13px;
  color: #555;
}
.filter_section_show_per_page input {
  all: unset;
  border: 1px solid #cacaca;
  padding: 0px 10px;
  width: 50px;
  font-size: 13px;
  color: #555;
  height: 34px;
}
/* Filter section search End*/
/* input field start*/
.input_field label {
  font-size: 14px;
  display: block;
}
.input_field input {
  all: unset;
  border: 1px solid #cacaca;
  font-size: 14px;
  text-indent: 10px;
  color: #555;
  height: 36px;
  width: 100%;
  border-radius: 4px;
}
/* input field end*/

/* quill  */
/* .leave-policy-quil {
  min-height: 800px;
} */
.leave-policy-quill .ql-toolbar.ql-snow + .ql-container.ql-snow {
  height: 400px;
}

/* policy */
.policy-modal-view-edit .nav-link {
  color: var(--primary-color) !important;
  border-color: #cacaca;
  margin-right: 4px;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #ffffff !important;
  background-color: var(--primary-color);
}
@media screen and (min-width: 576px) {
  .policy-modal-view-edit .modal-dialog {
    max-width: 800px !important;
    margin-left: auto;
    margin-right: auto;
  }
}
/* @media screen and (max-width: 625px) {
  .policy-modal-view-edit .modal-dialog {
    border: 1px solid red;
  }
  .policy-modal-view-edit .modal-body {
    border: 1px solid red;
    width: 700px;
    overflow-x: auto;
  }
} */

/* Forget Password */
.password-req-section-list {
  list-style: disc !important;
  margin-left: 18px;
  margin-bottom: 12px;
}
/* select option */
.is-option-selected {
  background-color: #bab5b5;
  color: white;
}

/* Input select Label */
.select-option-label {
  font-size: 14px;
  display: block;
}

/* Create Application modal */
.application-date-section .react-datepicker-wrapper {
  width: 100%;
}
.async-selection-application-user {
  width: 300px;
}
@media screen and (max-width: 440px) {
  .async-selection-application-user {
    width: 230px !important;
  }
}
@media screen and (max-width: 368px) {
  .async-selection-application-user {
    width: 180px !important;
  }
}

/* pagination */
.pagination {
  /* margin-top: 8px; */
  /* margin-bottom: 35px; */
  /* display: flex; */
  /* list-style: none; */
  text-decoration: none;
  /* outline: none; */
}

.pagination > .active > a {
  background-color: var(--primary-color);
  color: #fff !important;
  text-decoration: none;
}

.pagination > li > a,
.pagination > li > span {
  background-color: #fff;
  color: black !important;
  text-decoration: none;
  /* outline: none; */
  cursor: pointer;
}

.pagination > li > a {
  border: 1px solid var(--primary-color);
  padding: 5px 10px;
  /* outline: none; */
  cursor: pointer;
  text-decoration: none;
}

.pagination > li > a,
.pagination > li > span {
  color: #009ef7;
  background-color: rgb(255, 255, 255);
}

@media screen and (max-width: 514px) {
  .filter-date-picker-section {
    margin-top: 12px;
  }
}

/* chart css */
.employee-dashboard-chart canvas {
  width: 100% !important;
  height: 100% !important;
}
canvas {
}

/*  */

/* Otp */
.otp-modal-section .modal-dialog {
  /* 
  margin: 0 auto; */
  width: 550px;
  margin-right: auto;
  margin-left: auto;
}
.otp-modal-section .modal-content {
  border-radius: 24px;
}

.otp-modal-section input {
  width: 46px !important;
  height: 60px;
  border-radius: 4px;
  border: 1px solid #cacaca;
  margin-right: 16px;
}
